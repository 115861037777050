import React from "react";

const WebplaceContext = React.createContext({
  navbarItems: {},
  homeBanner: {},
  homeAboutus: {},
  homeShowcaseItems: {},
  homeNavOptionStyles: {},
  homeSocials: {},
  locationInformation: {},
  contactInformation: {},
  footerInformation: {},
});

export default WebplaceContext;
